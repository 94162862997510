import React, { useContext, useEffect, useState, useRef } from 'react'

import useTextfield from '../../Hooks/useTextfield.jsx'
import useFileUploader from '../../Hooks/useFileUploader.jsx'

import { Context } from '../../../index.js'
import { observer } from 'mobx-react-lite'

import Button from '../Components/Button.jsx'
import Textfield from '../Components/Textfield.jsx'
import Icon from '../Components/Icon.jsx'
import Select from '../Components/Select.jsx'

import '../../../Styles/Slides/Slide6.scss'

const Slide6 = (_) => {
    let { Service } = useContext(Context)

    let DropzoneRef = useRef(null)
    let UploadInputRef = useRef(null)

    let [clubSocials, setClubSocials] = useState([])
    let [photos, setPhotos] = useState([])
    let [isLoading, setIsLoading] = useState(false)
    let [isCorrect, setIsCorrect] = useState(false)

    let phoneNumber = useTextfield('', { length: [ 7, 30 ], empty: true, phoneNumber: true })
    let { UploadInputProps } = useFileUploader({ 
        DropzoneRef, UploadInputRef, 
        accept: [ 'image/png', 'image/jpeg' ], 
        maxSize: 5 * 1024 * 1024,
        onUpload: r => {
            r = r.map(el => ({ file: el, description: undefined }))
            
            setPhotos(prev => { 
                let New = [...prev, ...r]

                if (New.length > 10) {
                    New = New.splice(0, 10)
                    Service.snackbar('Максимальное количество файлов - 10. Лишние файлы были удалены.', 'warning')
                }

                return New
            })
        }
    })
    
    let handleNextSlide = async _ => { 
        if (!isCorrect) return

        setIsLoading(true)

        let UploadedPhotos = []

        for await (let thisPhoto of photos) {
            try {
                let UploadPhoto = await Service.upload(thisPhoto.file)

                UploadedPhotos.push({ file: UploadPhoto.file, description: thisPhoto.description })
            } catch(e) {
                Service.snackbar('Произошла ошибка при загрузке фотографий. Попробуйте ещё раз', 'error')
                setIsLoading(false)
                return console.error(e)
            }
        }

        Service.setData({ 
            data: {
                phone_number: phoneNumber.value,
                photos: UploadedPhotos,
                social: clubSocials
            }
        })

        setIsLoading(false)
        
        Service.setActiveSlide(7)
    }

    let handleAddSocial = _ => {
        setClubSocials(prev => [...prev, { network: '', url: '' }])
    }

    useEffect(_ => {
        if (phoneNumber.error.status === true || phoneNumber.isEdited === false) return setIsCorrect(false)
        if (photos.length !== 10) return setIsCorrect(false)

        for (let thisSocial of clubSocials) {
            if (!thisSocial) return setIsCorrect(false)
            if (typeof thisSocial !== 'object') return setIsCorrect(false)
            if (!Object.keys(thisSocial).length) return setIsCorrect(false)
            if (!thisSocial.network || !thisSocial.url) return setIsCorrect(false)
        }

        for (let thisPhoto of photos) {
            if (!thisPhoto) return setIsCorrect(false)
            if (typeof thisPhoto !== 'object') return setIsCorrect(false)
            if (!Object.keys(thisPhoto).length) return setIsCorrect(false)
            if (!thisPhoto.file || !thisPhoto.description) return setIsCorrect(false)
        }

        setIsCorrect(true)
    }, [phoneNumber, clubSocials, photos])
    
    return (
        <column className={`app__slide-main${isLoading ? ' loading' : ''}`} align='center'>
            <h3 className='row_1'>Контакты</h3>

            <div className='slide__content'>
                <column className='content__column row_2' align='center'>
                    <h5 className='content__column-title'>Номер телефона</h5>

                    <column className='content__column-data'>
                        <Textfield  
                            autocomplete='off' 
                            placeholder='+380123456789' 
                            trailing={ <Icon use='#icon_phone' className='f-textfield__trailing' /> }
                            {...phoneNumber} />
                    </column>
                </column>

                <column className='content__column row_3' align='center'>
                    <h5 className='content__column-title'>Социальные сети</h5>

                    <column className='content__column-data social__list'>
                        {
                            clubSocials.map(({ network, url }, index) => <SocialRow key={index} {...{index, network, url, setClubSocials}} />)
                        }

                        {
                            clubSocials.length < 3 &&
                            <row className='social__list-add'>
                                <Button 
                                    label='Добавить' 
                                    color='secondary' 
                                    icon={{ use: '#icon_create' }}
                                    onClick={handleAddSocial} />
                            </row>
                        }
                    </column>
                </column>

                <column className='content__column row_4' align='center'>
                    <h5 className='content__column-title'>
                        Фотографии
                    </h5>

                    <block className='message info'>
                        <row justify='center' align='center'>
                            <Icon use='#icon_info' />
                            <p>Загрузи ровно 10 фотографий, и кратко опиши каждую</p>
                        </row>
                    </block>

                    <column className='content__column-data photos__block' ref={DropzoneRef}>
                        <div class="dropzone-overlay">
                            <Icon use='#icon_upload' />
                        </div>

                        {
                            photos.length < 10 &&
                            <row className='photos__block-upload'>
                                <label className='f-button f-button__secondary' htmlFor='fileSelect'>
                                    <icon class="f-button__icon"><svg viewBox="0 0 28 28"><use fill="currentColor" href="#icon_upload"></use></svg></icon>
                                    <text>Загрузить</text>

                                    <input id='fileSelect' type='file' name='file' accept='image/png, image/jpeg' multiple {...UploadInputProps} />
                                </label>
                            </row>
                        }
                        {
                            photos.length > 0 &&
                            photos.map((photo, index) => <PhotoRow key={index} {...{index, photo, setPhotos}} />)
                        }
                    </column>
                </column>

                <row className='row_5' justify='between'>
                    <Button 
                        color='secondary'
                        label='Назад' 
                        icon={{ use: '#icon_back', direction: false }} 
                        onClick={_ => Service.setActiveSlide(5)} />

                    <Button 
                        label='Далее' 
                        icon={{ use: '#icon_next', direction: true }} 
                        disabled={ !isCorrect }
                        onClick={handleNextSlide} />
                </row>
            </div>
        </column>
    )
}

const PhotoRow = ({ index, photo, setPhotos }) => {
    let photoDescription = useTextfield('', { length: [ 2, 30 ], empty: true }, { isEdited: true })

    let handleEdit = ({ index, file, description }) => {
        setPhotos(prev => prev.map((el, i) => {
            if (i !== index) return el

            return { file, description }
        }))
    }

    let handleDelete = _ => {
        setPhotos(prev => prev.filter((_, i) => {
            if (i !== index) return true

            return false
        }))
    } 

    useEffect(_ => {
        if (photoDescription.error.status === true || photoDescription.isEdited === false) return handleEdit({ index, file: photo.file, description: undefined })

        handleEdit({ index, file: photo.file, description: photoDescription.value })
    }, [photoDescription.value, photoDescription.error])

    return (
        <row className='photos__block-row' align='start'>
            <img className='photo__image' src={photo?.file?.preview} alt='' />

            <Textfield  
                className='photo__description'
                autocomplete='off' 
                placeholder='Описание' 
                trailing={ <Icon use='#icon_details' className='f-textfield__trailing' /> }
                {...photoDescription} />

            <Button 
                className='photo__delete'
                color='red' 
                icon={{ use: '#icon_delete' }} 
                onClick={handleDelete} />
        </row>
    )
}

const SocialRow = ({ index, setClubSocials }) => {
    let socialType = useTextfield('', { empty: true }, { isEdited: true })
    let socialData = useTextfield('', { length: [ 5, 175 ], empty: true, isUrl: true }, { isEdited: true })

    
    let handleEdit = ({ network, url }) => {
        setClubSocials(prev => prev.map((el, i) => {
            if (i !== index) return el

            return { network, url }
        }))
    }

    let handleDelete = _ => {
        setClubSocials(prev => prev.filter((_, i) => {
            if (i !== index) return true

            return false
        }))
    } 

    useEffect(_ => {
        if (socialType.error.status === true || socialType.isEdited === false) return handleEdit({ index, network: undefined, url: undefined })
        if (socialData.error.status === true || socialData.isEdited === false) return handleEdit({ index, network: undefined, url: undefined })

        handleEdit({ index, network: socialType.value, url: socialData.value })
    }, [socialType.value, socialType.error, socialData.value, socialData.error])

    return (
        <column className='social__list-row' align='center'>
            <Select 
                className='social__row-select'
                options={[
                    { text: 'Выбери сеть', disabled: true, selected: true, value: '' },
                    { text: 'Сайт', value: 'web' },
                    { text: 'Instagram', value: 'instagram' },
                    { text: 'VK', value: 'vk' },
                ]}
                trailing={ <Icon use='#icon_network' className='f-textfield__trailing' /> }
                {...socialType}
                />
            <row className='social__row-data' align='start'>
                <Textfield  
                    autocomplete='off' 
                    placeholder='Ссылка' 
                    trailing={ <Icon use='#icon_url' className='f-textfield__trailing' /> }
                    {...socialData} />
                <Button 
                    className='social__row-delete'
                    color='red' 
                    icon={{ use: '#icon_delete' }} 
                    onClick={_ => handleDelete(index)} />
            </row>
        </column>
    )
}

export default observer(Slide6)
