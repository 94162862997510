/* eslint-disable no-undef */

/* Reference: https://github.com/ErrorPro/react-google-autocomplete */

import { useState, useCallback, useRef, useEffect } from 'react'
import debounceFn from 'lodash.debounce'

import LoadScript from '../Controller/LoadScript'

const useMapSearch = ({ apiKey, libraries = 'places', language = 'ru', region = 'ru', debounce = 500 }) => {
    const scriptUrl = `https://maps.googleapis.com/maps/api/js?v=beta&key=${apiKey}&libraries=${libraries}&language=${language}&region=${region}`

    const [isLoading, setIsLoading] = useState(false)
    const [query, setQuery] = useState('')
    const [predictions, setPredictions] = useState([])

    const session = useRef(null)
    const autoCompleteService = useRef(null)
    const placesService = useRef(null)

    let handleLoadScript = useCallback(_ => LoadScript(scriptUrl), [scriptUrl])

    const getPredictions = useCallback(
        debounceFn(optionsArg => {
            if (autoCompleteService.current && optionsArg.input)
                autoCompleteService.current.getQueryPredictions(
                    {
                        sessionToken: session.current,
                        ...optionsArg,
                    },
                    r => {
                        setIsLoading(false)
                        setPredictions(r || [])
                    }
                )
        }, debounce),
        [debounce]
    )

    useEffect(_ => {
        let Start = _ => {
            if (!google) return console.error('Google not found')

            autoCompleteService.current = new google.maps.places.AutocompleteService()
            placesService.current = new google.maps.places.PlacesService(document.createElement('div'))

            session.current = new google.maps.places.AutocompleteSessionToken()
        }

        handleLoadScript().then(_ => Start())
    }, [])

    return {
        isLoading,
        query,
        predictions,
        sessionToken: session.current,
        autoCompleteService: autoCompleteService.current,
        placesService: placesService.current,
        getPredictions: query => {
            if (!query) return setPredictions([])

            setQuery(query || '')
            setIsLoading(query ? true : false)
            getPredictions({ input: query || undefined })
            return;
        },
    }
}

export default useMapSearch
