import React, { useContext, useEffect, useState } from 'react'

import useTextfield from '../../Hooks/useTextfield.jsx'

import Tippy from '@tippyjs/react'
import { Context } from '../../../index.js'
import { observer } from 'mobx-react-lite'

import thinking from '../../../Assets/Images/Emoji/thinking.png'

import Button from '../Components/Button.jsx'
import Textfield from '../Components/Textfield.jsx'
import Icon from '../Components/Icon.jsx'
import Select from '../Components/Select.jsx'

import '../../../Styles/Slides/Slide4.scss'

const Slide4 = (_) => {
    let { Service } = useContext(Context)

    let [clubRooms, setClubRooms] = useState([])
    let [editingIndex, setEditingIndex] = useState(0)
    let [showModal, setShowModal] = useState(false)
    let [isCorrect, setIsCorrect] = useState(false)

    useEffect(() => {
        Service.getCountry(Service.data?.position?.country_code)
    }, [])

    let handleNextSlide = (_) => {
        if (clubRooms.length === 0) return

        Service.setData({ rooms: clubRooms })
        Service.setActiveSlide(5)
    }

    let handleEdit = index => {
        setEditingIndex(index)
        setShowModal(true)
        Validate()
    }
    let handleDelete = index => {
        setClubRooms(prev => prev.filter((_, i) => {
            if (i !== index) return true

            return false
        }))
        Validate()
    } 

    let handleCreate = _ => handleEdit(clubRooms.length)

    let Validate = _ => {
        if (clubRooms.length === 0) return setIsCorrect(false)

        for (let thisRoom of clubRooms) {
            if (!thisRoom) return setIsCorrect(false)
            if (typeof thisRoom !== 'object') return setIsCorrect(false)
            if (!Object.keys(thisRoom).length) return setIsCorrect(false)
            if (thisRoom.name === undefined) return setIsCorrect(false)
        }

        setIsCorrect(true)
    }

    return (
        <column align='center'>
            {
                showModal &&
                <EditModal {...clubRooms[editingIndex]} {...{editingIndex, setClubRooms, showModal, setShowModal, Validate}} currency={Service.currency} />
            }

            <h3 className='row_1'>Комнаты</h3>


            <div className='slide__content'>
                <column align='center'>
                    <block className='message info row_2'>
                        <row justify='center' align='center'>
                            <Icon use='#icon_info' />
                            <p>Добавь информацию о каждой комнате с компьютером/консолью</p>
                        </row>
                    </block>

                    <block className='rooms__list row_3'>
                        {clubRooms.length > 0 ? (
                            <>
                                <row className='rooms__list-header' justify='between'>
                                    <p className='col_1'>Название комнаты</p>
                                    <p className='col_2'>Тип</p>
                                    <p className='col_3'>Цена</p>
                                    <p className='col_4'>Действия</p>
                                </row>

                                <column className='rooms__list-body'>
                                    {
                                        clubRooms.map(({ name, price, type }, index) => <RoomRow key={index} {...{name, price, type, index, handleEdit, handleDelete}} currency={Service.currency} />)
                                    }
                                </column>
                            </>
                        ) : (
                            <row className='rooms__list-empty' align='center' justify='center'>
                                <img src={thinking} alt='' />
                                <h5>Тут ещё ничего нет</h5>
                            </row>
                        )}

                        <row className='rooms__list-add'>
                            <Button 
                                label='Добавить комнату' 
                                color='secondary' 
                                icon={{ use: '#icon_create' }}
                                onClick={handleCreate} />
                        </row>
                    </block>
                </column>

                <row className='row_4' justify='end'>
                    <Button 
                        label='Далее' 
                        icon={{ use: '#icon_next', direction: true }} 
                        disabled={ !isCorrect }
                        onClick={handleNextSlide} />
                </row>
            </div>
        </column>
    )
}

const RoomRow = ({ name, price, type, currency, index, handleEdit, handleDelete }) => {
    return (
        <row className='list__room' align='center' justify='between'>
            <h5 className='col_1'>{name}</h5>
            <h5 className='col_2'>{ type === 'pc' ? 'Компьютер' : 'Консоль'}</h5>
            <h5 className='col_3'>{price} {currency}</h5>
            <row className='col_4' justify='end'>
                <Button 
                    icon={{ use: '#icon_edit' }} 
                    onClick={_ => handleEdit(index)} />
                <Button 
                    color='red' 
                    icon={{ use: '#icon_delete' }} 
                    onClick={_ => handleDelete(index)} />
            </row>
        </row>
    )
}

const EditModal = ({ editingIndex, name, price, currency, type, isPrivate, details, setClubRooms, showModal, setShowModal, Validate }) => {
    const [isCorrect, setIsCorrect] = useState(false)
    const [isCancelPrompt, setIsCancelPrompt] = useState(false)

    let roomName = useTextfield(name || '', { empty: true, length: [3, 20] })
    let roomPrice = useTextfield(price || '', { empty: true, length: [1, 10], number: true })
    let roomIsPrivate = useTextfield(isPrivate ? 'true' : 'value', { empty: true })
    let roomType = useTextfield(type, { empty: true })
    
    let consoleName = useTextfield(details?.console || '', { empty: true }, { isEdited: true })
    let screen = useTextfield(details?.screen || '', { empty: true }, { isEdited: true })
    let cpu = useTextfield(details?.cpu || '', { empty: true }, { isEdited: true })
    let gpu = useTextfield(details?.gpu || '', { empty: true }, { isEdited: true })
    let ram = useTextfield(details?.ram || '', { empty: true, number: true }, { isEdited: true })


    let handleClose = _ => {
        setIsCancelPrompt(true)
    }

    let handleSaveChanges = _ => {
        if (!isCorrect) return

        let Data = {
            name: roomName.value,
            price: Number(roomPrice.value),
            currency,
            isPrivate: roomIsPrivate.value === 'true',
            type: roomType.value,
        }

        if (roomType.value === 'console') Data.details = { console: consoleName.value, screen: screen.value }
        else if (roomType.value === 'pc') Data.details = { cpu: cpu.value, gpu: gpu.value, ram: ram.value, screen: screen.value }

        setClubRooms(prev => { 
            if (prev[editingIndex]) {
                prev.map((el, i) => {
                    if (i !== editingIndex) return el
    
                    return Data
                })
            } else prev.push(Data)

            return prev
        })

        Validate()
        setShowModal(false)
    }

    useEffect(_ => {
        if (roomName.isEdited === false || roomPrice.isEdited === false || roomType.isEdited === false) return setIsCorrect(false)
        if (roomName.error.status || roomPrice.error.status || roomType.error.status) return setIsCorrect(false)
        if (roomType.value === 'console') {
            if (consoleName.isEdited === false || screen.isEdited === false) return setIsCorrect(false)
            if (consoleName.error.status || screen.error.status) return setIsCorrect(false)
        }
        if (roomType.value === 'pc') {
            if (cpu.isEdited === false || gpu.isEdited === false || ram.isEdited === false || screen.isEdited === false) return setIsCorrect(false)
            if (cpu.error.status || gpu.error.status || ram.error.status || screen.error.status) return setIsCorrect(false)
        }

        
        setIsCorrect(true)
    }, [roomName, roomPrice, roomType, roomIsPrivate, consoleName, screen, cpu, gpu, ram, name, price, type, details])

    return (
        <>
            <span className={`modal${isCancelPrompt ? ' show' : ''}`} id='cancelPrompt' style={{ zIndex: 9999 }}>
                <span className='modal__background'></span>

                <block className='modal__block'>
                    <column align='end'>
                        <h5>Вы уверены, что хотите отменить?</h5>

                        <row style={{ marginTop: '1rem' }}>
                            <Button 
                                label='Нет' 
                                color='primary' 
                                onClick={() => setIsCancelPrompt(false)} />
                            <Button
                                label='Да'
                                color='red'
                                onClick={() => {
                                    setShowModal(false)
                                    setIsCancelPrompt(false)
                                }}
                                style={{ marginLeft: '.5rem' }}
                            />
                        </row>
                    </column>
                </block>
            </span>

            <span className={`modal${showModal ? ' show' : ''}`} id='editRoomModal'>
                <span className='modal__background' onClick={handleClose}></span>

                <block className='modal__block'>
                    <div className='modal__block-content'>
                        <row className='content__row' align='center'>
                            <p className='content__row-title'>
                                Название комнаты
                            </p>
                            <Textfield
                                autocomplete='off'
                                className='content__row-data'
                                placeholder='Название'
                                trailing={ <Icon use='#icon_edit' className='f-textfield__trailing' /> }
                                {...roomName}
                            />
                        </row>

                        <row className='content__row' align='center'>
                            <p className='content__row-title'>
                                Цена за час
                            </p>

                            <row className='content__row-data'>
                                <Textfield
                                    autocomplete='off'
                                    placeholder='Цена'
                                    trailing={ <Icon use='#icon_dollar' className='f-textfield__trailing' /> }
                                    leading={ <p className='f-textfield__leading currency'>{currency}/час</p> }
                                    {...roomPrice}
                                />
                            </row>
                        </row>

                        <row className='content__row' align='center'>
                            <p className='content__row-title'> Общая комната? </p>

                            <Select
                                className='content__row-data'
                                trailing={ <Icon use='#icon_details' className='f-textfield__trailing' /> }
                                defaultValue={isPrivate ? 'true' : 'false'}
                                options={[
                                    { text: 'Общая', value: 'false', selected: true },
                                    { text: 'Закрытая', value: 'true' },
                                ]}
                                {...roomIsPrivate} />
                        </row>

                        <row className='content__row' align='center'>
                            <p className='content__row-title'> Тип комнаты </p>

                            <Select
                                className='content__row-data'
                                trailing={ <Icon use='#icon_details' className='f-textfield__trailing' /> }
                                defaultValue={type || ''}
                                options={[
                                    { text: 'Выберите', value: '', disabled: true, selected: true },
                                    { text: 'Компьютер', value: 'pc' },
                                    { text: 'Консоль', value: 'console' },
                                ]}
                                {...roomType} />
                        </row>

                        <div className={roomType.value || ''} id='details'>
                            <row className='content__row' id='cpu' align='center'>
                                <p className='content__row-title'> 
                                    Процессор 
                                    <Tippy content='Полное название процессора (нп. Intel Core i7-12700k)'>
                                        <icon>
                                            <svg viewBox='0 0 28 28'> <use href='#icon_question' fill='currentColor' /> </svg>
                                        </icon>
                                    </Tippy>
                                </p>

                                <row className='content__row-data'>
                                    <Textfield
                                        autocomplete='off'
                                        placeholder='Введи название'
                                        trailing={ <Icon use='#icon_cpu' className='f-textfield__trailing' /> }
                                        {...cpu}
                                    />
                                </row>
                            </row>
                            <row className='content__row' id='gpu' align='center'>
                                <p className='content__row-title'> 
                                    Видеокарта 
                                    <Tippy content='Полное название видеокарты (нп. Nvidia GeForce RTX 3070)'>
                                        <icon>
                                            <svg viewBox='0 0 28 28'> <use href='#icon_question' fill='currentColor' /> </svg>
                                        </icon>
                                    </Tippy>
                                </p>

                                <row className='content__row-data'>
                                    <Textfield
                                        autocomplete='off'
                                        placeholder='Введи название'
                                        trailing={ <Icon use='#icon_gpu' className='f-textfield__trailing' /> }
                                        {...gpu}
                                    />
                                </row>
                            </row>
                            <row className='content__row' id='ram' align='center'>
                                <p className='content__row-title'> 
                                    Оперативная память 
                                    <Tippy content='Количество оперативной памяти в ГБ'>
                                        <icon>
                                            <svg viewBox='0 0 28 28'> <use href='#icon_question' fill='currentColor' /> </svg>
                                        </icon>
                                    </Tippy>
                                </p>

                                <row className='content__row-data'>
                                    <Textfield
                                        autocomplete='off'
                                        placeholder='Введи количество ГБ'
                                        trailing={ <Icon use='#icon_ram' className='f-textfield__trailing' /> }
                                        leading={ <p className='f-textfield__leading'>ГБ</p> }
                                        {...ram}
                                    />
                                </row>
                            </row>
                            <row className='content__row' id='console' align='center'>
                                <p className='content__row-title'> 
                                    Консоль 
                                    <Tippy content='Полное название консоли (нп. Sony PlayStation 5)'>
                                        <icon>
                                            <svg viewBox='0 0 28 28'> <use href='#icon_question' fill='currentColor' /> </svg>
                                        </icon>
                                    </Tippy>
                                </p>
                                

                                <row className='content__row-data'>
                                    <Textfield
                                        autocomplete='off'
                                        placeholder='Введи название'
                                        trailing={ <Icon use='#icon_console' className='f-textfield__trailing' /> }
                                        {...consoleName}
                                    />
                                </row>
                            </row>
                            <row className='content__row' id='screen' align='center'>
                                <p className='content__row-title'> 
                                    Монитор/телевизор 
                                    <Tippy content='Фирма, диагональ и частота монитора или телевизора (нп. Samsung 27" 144hz)'>
                                        <icon>
                                            <svg viewBox='0 0 28 28'> <use href='#icon_question' fill='currentColor' /> </svg>
                                        </icon>
                                    </Tippy>
                                </p>

                                <row className='content__row-data'>
                                    <Textfield
                                        autocomplete='off'
                                        placeholder='Введи название'
                                        trailing={ <Icon use='#icon_screen' className='f-textfield__trailing' /> }
                                        {...screen}
                                    />
                                </row>
                            </row>
                        </div>

                        <row align='center' justify='end' style={{ marginTop: '1.5rem' }}>
                            <Button 
                                label='Отменить' 
                                color='secondary' 
                                onClick={handleClose} />
                            <Button 
                                label='Сохранить' 
                                color='primary' 
                                style={{ marginLeft: '.5rem' }} 
                                disabled={!isCorrect}
                                onClick={handleSaveChanges} />
                        </row>
                    </div>
                </block>
            </span>
        </>
    )
}

export default observer(Slide4)
