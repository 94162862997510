import React, { useContext } from 'react'

import { Context } from '../../../index.js'
import { observer } from 'mobx-react-lite'

import handwave from '../../../Assets/Images/Emoji/handwave.png'

import Button from '../Components/Button.jsx'

import '../../../Styles/Slides/Slide0.scss'

const Slide0 = _ => {
    let { Service } = useContext(Context)

    return (
        <column align='center'>
            <row className='row_1' justify='center'>
                <img src={handwave} alt="" />
                <h3>Привет, {Service.user}!</h3>
            </row>

            <h4 className='row_2'>Сейчас ты сможешь добавить свой клуб</h4>
            
            <Button className='row_3' label='Продолжить' icon={{ use: '#icon_next', direction: true }} onClick={e => Service.setActiveSlide(1)} />
        </column>
    )
}

export default observer(Slide0)
