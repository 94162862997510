import React from 'react'

const ErrorPage = ({ message, icon, fullscreen }) => {
    return (
        <error className={ fullscreen ? 'fullscreen' : '' }>
            <icon> 
                <svg viewBox="0 0 28 28"> <use href={ icon ? '#' + icon : '#icon_cloud_error' } fill="currentColor"/> </svg>
            </icon>
            <h3> { message || 'Unknown error' } </h3>
        </error>
    )
}

export default ErrorPage