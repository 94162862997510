import React, { useContext } from 'react'

import useTextfield from '../../Hooks/useTextfield.jsx'

import { Context } from '../../../index.js'
import { observer } from 'mobx-react-lite'

import Button from '../Components/Button.jsx'
import Textfield from '../Components/Textfield.jsx'
import Icon from '../Components/Icon.jsx'

import '../../../Styles/Slides/Slide1.scss'

const Slide1 = _ => {
    let { Service } = useContext(Context)

    let clubName = useTextfield('', { length: [ 3, 30 ], empty: true })

    let handleNextSlide = _ => { 
        if (clubName.error?.status === true) return

        Service.setData({ name: clubName.value })
        Service.setActiveSlide(2)
    }

    return (
        <column align='center'>
            <h3 className='row_1'>Начнём с простого</h3>
            <h4 className='row_2'>Как называется клуб?</h4>

            <Textfield 
                className='row_3' 
                autocomplete='off' 
                placeholder='Название клуба' 
                trailing={ <Icon use='#icon_edit' className='f-textfield__trailing' /> }
                {...clubName} />

            <Button 
                className={`row_4${ clubName.isEdited ? '' : ' hide' }`} 
                label='Далее' 
                icon={{ use: '#icon_next', direction: true }} 
                disabled={ clubName.error?.status }
                onClick={handleNextSlide} />
        </column>
    )
}

export default observer(Slide1)
