import { forwardRef } from 'react'

import '../../../Styles/Components/Textfield.scss'

const Textfield = forwardRef(({ disabled, color, className, trailing, leading, error = {}, ...props }, ref) => {
    const classList = ['f-textfield']

    if (color) classList.push(`f-textfield__${color}`)
    if (disabled) classList.push(`f-textfield__disabled`)
    if (className) classList.push(className)
    if (error?.status === true) classList.push('f-textfield__error')

    return (
        <div className='f-textfield__container'>
            <label className={classList.join(' ')}>
                {trailing}
                <input className='f-textfield__input' {...props} />
                {leading}
            </label>

            <p className='f-textfield__errormessage'>{error.message}</p>
        </div>
    )
})

export default Textfield
