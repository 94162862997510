import React, { useContext, useEffect } from 'react'

import useBeforeUnload from './Modules/Hooks/useBeforeUnload.jsx'

import { Context } from './index.js'
import { observer } from 'mobx-react-lite'

import ErrorPage from './Modules/Views/Error.jsx'

import Slide0 from './Modules/Views/Slides/Slide0.jsx'
import Slide1 from './Modules/Views/Slides/Slide1.jsx'
import Slide2 from './Modules/Views/Slides/Slide2.jsx'
import Slide3 from './Modules/Views/Slides/Slide3.jsx'
import Slide4 from './Modules/Views/Slides/Slide4.jsx'
import Slide5 from './Modules/Views/Slides/Slide5.jsx'
import Slide6 from './Modules/Views/Slides/Slide6.jsx'
import Slide7 from './Modules/Views/Slides/Slide7.jsx'
import { useState } from 'react'

let Slides = [<Slide0 />, <Slide1 />, <Slide2 />, <Slide3 />, <Slide4 />, <Slide5 />, <Slide6 />, <Slide7 />]

const App = _ => {
    let { Service } = useContext(Context)

    let [isEditing, setIsEditing] = useState(false)

    useBeforeUnload({ 
        enabled: isEditing,
        message: 'Вы уверены, что хотите закрыть вкладку?'
    })

    useEffect(() => { 
        Service.checkAuth()
            .then(_ => {
                document.title = 'Добавление клуба - clubs.flittis.xyz'
            })
    }, [Service])

    useEffect(_ => {
        if (Service.activeSlide === 0 || Service.activeSlide === 7) setIsEditing(false)
        else setIsEditing(true)
    }, [Service.activeSlide])

    if (Service.isLoading || Service.isAuth === undefined) document.querySelector('#app').classList.add('loading')
    else document.querySelector('#app').classList.remove('loading')

    if (Service.isAuth === undefined) return <></>
    if (Service.error?.status === true) return <ErrorPage fullscreen={true} {...Service.error} />

    return (
        <div className='app__wrapper'>
            <header>
                <div className='header__content'>
                    <h4>Clubs Bot</h4>
                    
                    <row className='header__content-user' align='center'>
                        <h5>{Service.user}</h5>
                        
                        <div className='header__user-avatar'>
                            <p>{Service.user[0]}</p>
                        </div>
                    </row>
                </div>
            </header>

            <div className={`app__content${Service.isSlideTransition ? ' transition' : ''}`}>
                <div className='app__slide' id={`slide_${Service.activeSlide}`}>    
                    { Slides[Service.activeSlide] }
                </div>
            </div>
        </div>
    )
}

export default observer(App)
