import { forwardRef } from 'react'

import '../../../Styles/Components/Button.scss'

import Icon from './Icon'

const Button = forwardRef(({ label, color, icon, disabled, className, ...props }, ref) => {
    let classList = [ 'f-button' ], content = [];

    if (color) classList.push(`f-button__${color}`)
    if (disabled) classList.push(`f-button__disabled`)
    if (className) classList.push(className)

    if (label) content.push(<text>{label}</text>)
    if (icon) {
        let iconElement = <Icon className="f-button__icon" {...icon}/>

        if (icon.direction === true) content.push(iconElement)
        else content.unshift(iconElement)
    }

    return (
        <button ref={ref} className={classList.join(' ')} {...props}>
            {content}
        </button>
    )
})

export default Button