import { forwardRef } from 'react'

const Icon = forwardRef(({ use, path, viewBox, direction, ...props }, ref) => {
    return (
        <icon {...props} ref={ref}>
            <svg viewBox={ viewBox || '0 0 28 28' }>
                {
                    use ?
                    <use fill="currentColor" href={ use } /> :
                    <path fill="currentColor" d={ path } />
                }
            </svg>
        </icon>
    )
})

export default Icon