/* eslint-disable no-undef */

let LoadScript = url => {
    return new Promise((resolve, reject) => {
        if (typeof google !== 'undefined')
            if (google.maps && google.maps.api) return resolve(true)

        const scriptElements = document.querySelectorAll(`script[src*="${url}"]`)

        if (scriptElements && scriptElements.length) {
            if (typeof google !== 'undefined') return resolve(true)

            scriptElements[0].addEventListener('load', _ => resolve(true))
        } else {
            const el = document.createElement('script')
            el.src = url
    
            document.body.appendChild(el)
    
            el.addEventListener('load', _ => resolve(true))
        }
    })
}

export default LoadScript