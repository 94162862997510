import React, { useContext, useState, useEffect } from 'react'

import useTextfield from '../../Hooks/useTextfield.jsx'
import useMapSearch from '../../Hooks/useMapSearch.jsx'

import { Context } from '../../../index.js'
import { observer } from 'mobx-react-lite'

import Button from '../Components/Button.jsx'
import Textfield from '../Components/Textfield.jsx'
import Icon from '../Components/Icon.jsx'

import '../../../Styles/Slides/Slide3.scss'

const Slide3 = _ => {
    let { Service } = useContext(Context)

    let [isCorrect, setIsCorrect] = useState(false)
    let [skipSearch, setSkipSearch] = useState(false)
    let [position, setPosition] = useState({  })

    let clubAddress = useTextfield('', { length: [ 3, 50 ], empty: true })
    let { placesService, predictions, getPredictions, isLoading } = useMapSearch({ apiKey: Service.config.GOOGLEMAPS_API_KEY })

    let handleNextSlide = _ => { 
        if (isCorrect === false) return

        Service.setData({ position: position })
        Service.setActiveSlide(4)
    }

    let selectPlace = (place_id) => {
        placesService?.getDetails(
            { placeId: place_id, fields: [ 'address_components', 'geometry.location' ], language: 'ru', region: 'ru' },
            placeDetails => {
                if (!placeDetails || !placeDetails.geometry?.location || !placeDetails.address_components?.length) return Service.snackbar('Неккоректное положение', 'error', 'error')

                let Data = {
                    location: {
                        type: 'Point',
                        coordinates: [placeDetails.geometry.location.lng(), placeDetails.geometry.location.lat()]
                    }
                }

                placeDetails.address_components.reverse().forEach(el => {
                    if (el.types.includes('country')) {
                        Data.country_code = el.short_name
                        return Data.country = el.long_name
                    } 
                    if (el.types.includes('locality')) return Data.city = el.long_name
                    if (el.types.includes('route')) return Data.address = el.long_name
                    if (!Data.address && el.types.includes('sublocality')) return Data.address = el.long_name
                    if (el.types.includes('street_number') || el.types.includes('premise')) return Data.address = `${Data.address || ''} ${el.long_name}`
                })

                setPosition(Data)
                setIsCorrect(true)
                setSkipSearch(true)
                clubAddress.setValue(`${Data.country}, ${Data.city}, ${Data.address}`)
                getPredictions()
            }
        )
    }

    useEffect(_ => {
        if (clubAddress.error.status === true) return getPredictions()
        if (skipSearch === true) return setSkipSearch(false)

        setIsCorrect(false)
        getPredictions(clubAddress.value)
    }, [clubAddress.error.status, clubAddress.value])

    return (
        <column align='center'>
            <h4 className='row_1'>Где находится клуб?</h4>
            
            <div className='slide__content'>
                <column className='content__column' align='center'>
                    <block className='message info row_2'>
                        <row justify='center' align='center'>
                            <Icon use='#icon_info' />
                            <p>Введи название или адрес клуба, и выбери наиболее подходящий вариант</p>
                        </row>
                    </block>

                    <Textfield 
                        className='row_3' 
                        autocomplete='off' 
                        placeholder='Начните вводить адрес' 
                        trailing={ <Icon use='#icon_map' className='f-textfield__trailing' /> }
                        leading={ <Icon use='#icon_loading' className={`f-textfield__leading${isLoading ? ' show' : ''}`} />}
                        {...clubAddress} />

                    <column className={`location__list${predictions.length > 0 ? ' show' : ''}`}>
                        { predictions.map(place => <PlaceRow key={place.place_id} {...place} {...{selectPlace}} />) }
                    </column>
                </column>


                <row className='row_4' justify='end'>
                    <Button 
                        label='Далее' 
                        icon={{ use: '#icon_next', direction: true }} 
                        disabled={ !isCorrect }
                        onClick={handleNextSlide} />
                </row>
            </div>
        </column>
    )
}

const PlaceRow = ({ description, place_id, selectPlace }) => {
    let handleClick = _ => {
        selectPlace(place_id)
    }

    return (
        <row className='location__list-row' align='center' onClick={handleClick}>
            <Icon use='#icon_map_pin' />
            <p>{description}</p>
        </row>
    )
}

export default observer(Slide3)
