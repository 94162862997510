import { createContext } from 'react'
import { createRoot } from 'react-dom/client'

import 'izitoast/dist/css/iziToast.min.css'
import 'tippy.js/dist/tippy.css'

import './Styles/Main.scss'
import './Styles/Components.scss'
import './Styles/iziToast.scss'

import App from './App'

import _Store from './Modules/Store/ServiceStore.js'
import Icons from './Modules/Views/Icons'

export const Service = new _Store()
export const Context = createContext({ Service })

const root = createRoot(document.getElementById('app'))

root.render(
    <Context.Provider value={{ Service }}>
        <Icons />
        <App />
    </Context.Provider>
)

