import React, { useContext, useState, useEffect } from 'react'

import useTextfield from '../../Hooks/useTextfield.jsx'

import { Context } from '../../../index.js'
import { observer } from 'mobx-react-lite'

import Button from '../Components/Button.jsx'
import Textfield from '../Components/Textfield.jsx'
import Icon from '../Components/Icon.jsx'
import Select from '../Components/Select.jsx'

import '../../../Styles/Slides/Slide2.scss'

let Days = [ { label: 'Понедельник', index: 1 }, { label: 'Вторник', index: 2 }, { label: 'Среда', index: 3 },
             { label: 'Четверг', index: 4 }, { label: 'Пятница', index: 5 }, { label: 'Суббота', index: 6 },
             { label: 'Воскресенье', index: 0 } ]

const Slide2 = _ => {
    let { Service } = useContext(Context)

    let [schedule, setSchedule] = useState([])
    let [isCorrect, setIsCorrect] = useState(false)

    let handleNextSlide = _ => { 
        if (isCorrect === false) return

        Service.setData({ schedule: schedule })
        Service.setActiveSlide(3)
    }

    let Validate = _ => {
        if (schedule.length !== 7) return

        for (let thisDay of schedule) {
            if (!thisDay) return
            if (typeof thisDay !== 'object') return
            if (!Object.keys(thisDay).length) return
            if (thisDay.is_working === undefined) return
        }

        setIsCorrect(true)
    }

    return (
        <column align='center'>
            <h4 className='row_1'>График работы</h4>

            <div className='slide__content'>
                {
                    Days.map(({label, index}, i) => <Day key={index} {...{i, label, index, setSchedule, Validate}} />)
                }
                
                <row className={`row_9`} justify='end'>
                    <Button 
                        label='Далее' 
                        icon={{ use: '#icon_next', direction: true }} 
                        disabled={ !isCorrect }
                        onClick={handleNextSlide} />
                </row>
            </div>
            
        </column>
    )
}

const Day = ({ i, label, index, setSchedule, Validate }) => {
    let workType = useTextfield('', { empty: true })
    let timeFrom = useTextfield('', { empty: true, isTime: true })
    let timeTo = useTextfield('', { empty: true, isTime: true })

    useEffect(_ => {
        let scheduleDay = {}

        if (workType.value === '24h') {
            scheduleDay.is_working = true
            scheduleDay.is_24h = true
        } else if (workType.value === 'onTime') {
            if (!timeFrom.value || timeFrom.error.status === true) return
            if (!timeTo.value || timeTo.error.status === true) return

            scheduleDay.is_working = true
            scheduleDay.from = { hour: timeFrom.value.split(':')[0], minute: timeFrom.value.split(':')[1] }
            scheduleDay.to = { hour: timeTo.value.split(':')[0], minute: timeTo.value.split(':')[1] }
        } else if (workType.value === 'closed') scheduleDay.is_working = false

        if (Object.keys(scheduleDay).length === 0) return

        setSchedule(prev => {
            prev[index] = scheduleDay 
            return prev
        })
        Validate()
    }, [workType, timeFrom, timeTo, index, setSchedule, Validate])

    return (
        <row className={`content__row row_${i + 2}`} style={{ animationDelay: `${(i + 1) * 0.1 + 0.3}s` }} align='center'>
            <h5 className='content__row-title'>{label}</h5>

            <column className='content__row-data'>
                <Select 
                    className='schedule__typeselector'
                    options={[
                        { text: 'Выбери', disabled: true, selected: true, value: '' },
                        { text: 'Закрыто', value: 'closed' },
                        { text: 'Круглосуточно', value: '24h' },
                        { text: 'По графику', value: 'onTime' },
                    ]}
                    placeholder='Выберите'
                    trailing={ <Icon use='#icon_calendar_clock' className='f-textfield__trailing' /> }
                    {...workType}
                    />

                <row className={`schedule__timeinput${workType.value === 'onTime' ? ' show' : ''}`} align='center'>
                    <Textfield 
                        type='time'
                        className='' 
                        autocomplete='off' 
                        trailing={ <Icon use='#icon_clock' className='f-textfield__trailing' /> }
                        {...timeFrom}
                        />
                    -
                    <Textfield 
                        type='time'
                        className='' 
                        autocomplete='off' 
                        trailing={ <Icon use='#icon_clock' className='f-textfield__trailing' /> }
                        {...timeTo}
                        />
                </row>
            </column>
        </row>
    )
}

export default observer(Slide2)
