export let GenerateToken = (length) => {
    var Output = '', Characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (var i = 0; i < length; i++) 
        Output += Characters.charAt( Math.floor( Math.random() * Characters.length ));
    
    return Output;
}

export let formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    let k = 1024, dm = decimals < 0 ? 0 : decimals, 
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    let i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
