import { useEffect } from 'react'

const useFileUploader = ({ maxSize, accept = [], DropzoneRef, onUpload }) => {
    useEffect(_ => {
        if (!DropzoneRef?.current) return

        let El = DropzoneRef.current;

        let handleDragOver = _ => El.classList.add('dragover')
        let handleDragLeave = _ => El.classList.remove('dragover')
        let handlePrevent = e => {
            e.preventDefault()
            e.stopPropagation()
        }
        let handleDrop = e => {
            handleDragLeave()

            let droppedFiles = e?.dataTransfer?.files
            droppedFiles = Array.from(droppedFiles)
            
            if (accept?.length) droppedFiles = droppedFiles.filter(({type}) => accept.includes(type))
            if (maxSize !== 0) droppedFiles = droppedFiles.filter(({size}) => size <= maxSize)
            if (!droppedFiles) return

            droppedFiles = droppedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file)}))
            onUpload(droppedFiles)
        }

        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop']
            .forEach(e => El.addEventListener(e, handlePrevent)) 

        El.addEventListener('dragover', handleDragOver)
        El.addEventListener('dragenter', handleDragOver)
        El.addEventListener('dragleave', handleDragLeave)
        El.addEventListener('dragend', handleDragLeave)
        El.addEventListener('drop', handleDrop)

        return _ => {
            ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop']
                .forEach(e => El.removeEventListener(e, handlePrevent))

            El.removeEventListener('dragover', handleDragOver)
            El.removeEventListener('dragenter', handleDragOver)
            El.removeEventListener('dragleave', handleDragLeave)
            El.removeEventListener('dragend', handleDragLeave)
            El.removeEventListener('drop', handleDrop)
        }
    }, [DropzoneRef])
    
    return {
        UploadInputProps: {
            onChange: (e) => {
                let droppedFiles = e.target.files
                droppedFiles = Array.from(droppedFiles)
                
                if (accept?.length) droppedFiles = droppedFiles.filter(({type}) => accept.includes(type))
                if (maxSize !== 0) droppedFiles = droppedFiles.filter(({size}) => size <= maxSize)
                if (!droppedFiles) return
                
                droppedFiles = droppedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file)}))
                onUpload(droppedFiles)

                e.target.value = null
            }
        }
    }
}

export default useFileUploader