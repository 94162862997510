import Config from '../../Config.js'

import axios from 'axios'
import { makeAutoObservable } from 'mobx'
import iziToast from 'izitoast'

export default class Store {
    config = {
        MAIN_DOMAIN: 'http://localhost/',
        GOOGLEMAPS_API_KEY: undefined
    }
    error = {
        status: false, 
        message: '', 
        icon: null
    }

    
    data = {
        
    }
    uploading = { }
    activeSlide = 0 
    currency = 'руб'
    
    access_token = undefined
    user = undefined
    user_id = undefined
    isLoading = true
    isEditing = false
    isAuth = undefined
    isSlideTransition = false

    constructor() {
        makeAutoObservable(this)

        if (Config?.SERVER_URL) this.config.SERVER_URL = Config.SERVER_URL
        this.config.GOOGLEMAPS_API_KEY = Config.GOOGLEMAPS_API_KEY
    }

    setData(data) {
        for (const key in data) this.data[key] = data[key]

        console.log(data, this.data)
    }

    setActiveSlide(activeSlide) {
        this.isSlideTransition = true

        setTimeout(() => {
            this.activeSlide = activeSlide
            this.isSlideTransition = false
        }, 750)
    }

    setAccessToken(access_token) {
        this.access_token = access_token
    }

    setAuth(isAuth) {
        this.isAuth = isAuth
    }

    setUser(user) {
        this.user = user
    }

    setUserId(user_id) {
        this.user_id = user_id
    }

    setCurrency(currency) {
        if (!currency) return
        
        this.currency = currency
    }

    setLoading(isLoading) {
        this.isLoading = isLoading
    }

    setError({ status, message, icon }) {
        this.error.status = status
        this.error.message = message
        this.error.icon = icon
    }

    async create() {
        return new Promise(async (resolve, reject) => {
            try {
                await axios({
                    method: 'POST',
                    baseURL: Config.SERVER_URL,
                    url: 'api/create',
                    params: {
                        access_token: this.access_token,
                        user: this.user_id
                    },
                    data: this.data
                })

                resolve(true)
            } catch (e) {
                reject(e.response?.data?.error || 'Unknown error')
            }
        })
    }

    async checkAuth() {
        return new Promise(async (resolve, reject) => {
            try {
                if (!window.location.search) throw new Error('Token not defined')

                let Params = new URLSearchParams(window.location.search)
                let access_token = Params.get('access_token'),
                    user = Params.get('user')
                
                if (!access_token) throw new Error('Token not defined')
                if (!user) throw new Error('User not defined')

                let Response = await axios({
                    method: 'GET',
                    baseURL: Config.SERVER_URL,
                    url: 'api/checkToken',
                    params: {
                        access_token,
                        user
                    }
                })
    
                this.setUser(Response.data.response.user)
                this.setAccessToken(access_token)
                this.setUserId(user)
                this.setAuth(true)

                resolve(true)
            } catch (e) {
                this.setAuth(false)
                this.setError({ status: true, message: 'Access Denied', icon: 'icon_locked' })

                reject('Invalid access token')
            } finally {
                this.setLoading(false)
            }
        })
    }

    async getCountry(country_code) {
        if (!country_code) return

        return new Promise(async (resolve, reject) => {
            try {
                let Response = await axios({
                    method: 'GET',
                    baseURL: Config.SERVER_URL,
                    url: 'api/getCountry',
                    params: {
                        access_token: this.access_token,
                        user: this.user_id,
                        country_code
                    }
                })

                let { currency } = Response.data.response

                if (!currency) return reject(false)

                this.setCurrency(currency.currency_shortname || currency.currency_code)

                resolve(true)
            } catch (e) {
                console.error(e.response?.data || e)
                reject(false)
            }
        })
    }

    async upload(file) {
        return new Promise(async (resolve, reject) => {
            if (!file || !file.name) return reject('File not defined')

            try {
                let formData = new FormData()
                formData.append('file', file, encodeURIComponent(file.name))

                let response = await axios({
                    method: 'POST',
                    baseURL: Config.SERVER_URL,
                    url: '/api/upload',
                    data: formData,
                    headers: { 'Content-Type': 'multipart/form-data' },
                    params: { 
                        access_token: this.access_token,
                        user: this.user_id,
                    },
                })

                resolve(response?.data?.response)
            } catch (e) {
                this.snackbar(`Произошла ошибка при загрузке ${file.name}.\nПопробуйте ещё раз`, 'error', 'error')
                reject(e.response?.data?.error || 'Unknown error')
            }
        })
    }

    cancelUpload(id) {
        this.uploading[id].controller.abort()
        this.uploading[id].status = 'canceled'
    }

    snackbar(message, type = 'info', icon) {
        let toastConfig = {
            message: message,
            position: 'topCenter',
            theme: 'dark',
            close: true,
            animateInside: false,
            closeOnClick: true,
            icon: 'material-icons-round',
            iconText: 'info',
            timeout: 3000,
            progressBar: false,
            class: 'info',
        }

        if (type === 'error') {
            toastConfig.iconText = 'error'
            toastConfig.class = 'warning'
        } else if (type === 'warning') {
            toastConfig.iconText = 'warning'
            toastConfig.class = 'warning'
        }

        if (icon) toastConfig.iconText = icon

        iziToast.show(toastConfig)
    }
}
