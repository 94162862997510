import { useEffect } from 'react'

const useBeforeUnload = ({ enabled, message }) => {
    useEffect(_ => {
        if (enabled !== true) return

        let handleBeforeUnload = e => {  
            e.preventDefault()
            e.returnValue = message
    
            return
        }

        window.addEventListener('beforeunload', handleBeforeUnload)
        
        return _ => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [enabled, message])

    return
}

export default useBeforeUnload