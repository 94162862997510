import { useEffect } from 'react'
import { useState } from 'react'

const useTextfield = (initValue, validators, options) => {
    const [value, setValue] = useState(initValue)
    const [isEdited, setIsEdited] = useState(options?.isEdited || (initValue ? true : false))
    const [error, setError] = useState({
        status: false,
        message: undefined
    })

    useEffect(_ => {
        if (!isEdited) return

        if (validators && Object.keys(validators).length) {
            let ValidateResult = Validate(value, validators)

            setError({ status: ValidateResult ? true : false, message: ValidateResult || '' })
        }
    }, [value])

    return {
        value,
        setValue,
        isEdited,
        error,
        onChange: e => {
            if (!isEdited) setIsEdited(true)
        
            setValue(e.target.value)
        }
    }
}

const Validate = (value, validators) => {
    if (validators.empty === true && value.length === 0) return 'Обязательное поле'
    if (validators.length) {
        if (value.length < validators.length[0]) return 'Текст слишком короткий'
        if (validators.length[1] && value.length > validators.length[1]) return 'Текст слишком длинный'
    }
    if (validators.isTime) {
        if (value.indexOf(':') === -1 || value.length !== 5) return 'Неверный формат времени'

        let [hours, minutes] = value.split(':')

        hours = Number(hours)
        minutes = Number(minutes)

        if (isNaN(hours) || isNaN(minutes)) return 'Неверный формат времени'
        if (hours < 0 || hours > 23) return 'Неверный формат времени'
        if (minutes < 0 || minutes > 59) return 'Неверный формат времени'
    }
    if (validators.number && isNaN(Number(value))) return 'Данные должны быть в числовом виде' 
    if (validators.phoneNumber) {
        if (!value.startsWith('+')) return 'Неверный формат телефонного номера'
        if ((/^\+\d{6,18}$/gi).test(value) === false) return 'Неверный формат телефонного номера'
    }
    if (validators.isUrl) {
        if (!value.startsWith('http://') && !value.startsWith('https://')) return 'Неверная ссылка'
        if (value.indexOf('.') === -1) return 'Неверная ссылка'
    }

    return false
}

export default useTextfield
