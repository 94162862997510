import React, { useContext, useEffect, useState } from 'react'

import { Context } from '../../../index.js'
import { observer } from 'mobx-react-lite'

import cheer from '../../../Assets/Images/Emoji/cheer.png'
import shit from '../../../Assets/Images/Emoji/shit.png'

import Button from '../Components/Button.jsx'
import Icon from '../Components/Icon.jsx'

import '../../../Styles/Slides/Slide7.scss'

const Slide7 = (_) => {
    let { Service } = useContext(Context)

    let [isLoading, setIsLoading] = useState(true)
    let [isError, setIsError] = useState(false)
    let [error, setError] = useState({})
    let [tryAgain, setTryAgain] = useState(0)

    useEffect(_ => {
        setIsLoading(true)
        setIsError(false)

        console.log(JSON.stringify(Service.data))

        let TrySave = async () => {
            try {
                await Service.create()
            } catch(e) {
                console.error(e)
                setIsError(true)
                setError(e)
            } finally {
                setIsLoading(false)
            }
        }

        setTimeout(TrySave, 1500)
    }, [tryAgain])

    let thisStyle = 'loading';

    if (isError) thisStyle = 'error'
    else if (isLoading) thisStyle = 'loading'
    else thisStyle = 'success'

    return (
        <div className={`app__slide-main ${thisStyle}`}>
            <row className='screen__loading' justify='center'>
                <h3>Сохраняю данные...</h3>
            </row>

            <column className='screen__success' align='center'>
                <row className='screen__success-first' align='center'>
                    <img src={cheer} alt='cheer' />
                    <h3>Спасибо!</h3>
                </row>
                <row className='screen__success-second'>
                    <h4>Клуб {Service.data?.name} успешно сохранён</h4>
                </row>
            </column>

            <column className='screen__error' align='center'>
                <row className='screen__error-first' align='center'>
                    <img src={shit} alt='error' />
                    <h3>Упс!</h3>
                </row>

                <column className='screen__error-second' align='center'>
                    <h4>К сожалению, где-то произошла ошибка</h4>
                </column>

                <Button 
                    className='screen__error-third'
                    color='primary'
                    label='Назад' 
                    icon={{ use: '#icon_back' }} 
                    onClick={_ => Service.setActiveSlide(6)} />

                <Button 
                    disabled={tryAgain >= 1}
                    className='screen__error-third'
                    color='primary'
                    label='Попробовать ещё раз' 
                    icon={{ use: '#icon_loading' }} 
                    onClick={_ => (tryAgain < 1 && setTryAgain(prev => prev + 1))} />

                <block className='screen__error-fourth'>
                    <row justify='center' align='center'>
                        <Icon use='#icon_info' />
                        <p>Отправь скриншот этой страницы администрации, чтобы мы поскорее смогли решить проблему</p>
                    </row>
                </block>
                
                <block className='screen__error-fifth'>
                    <pre>
                        {JSON.stringify(error, null, 4)}
                    </pre>
                </block>
            </column>
        </div>
    )
}



export default observer(Slide7)
