import React, { useContext, useState, useEffect } from 'react'

import { Context } from '../../../index.js'
import { observer } from 'mobx-react-lite'

import Checkbox from '../Components/Checkbox.jsx'
import Button from '../Components/Button.jsx'
import Icon from '../Components/Icon.jsx'

import '../../../Styles/Slides/Slide5.scss'

const Slide5 = _ => {
    let { Service } = useContext(Context)

    let [services, setServices] = useState([])

    let handleNextSlide = _ => { 
        Service.setData({ services })
        Service.setActiveSlide(6)
    }

    let handleToggleOther = (val, isAdd) => {
        if (isAdd) {
            if (services.includes(val)) return
            
            setServices(prev => prev.concat([val]))
        } else {
            if (!services.includes(val)) return

            setServices(prev => prev.filter(el => val !== el))
        }
    }
    return (
        <column align='center'>
            <h3 className='row_1'>Удобства</h3>

            <div className='slide__content'>
                <column className='content__column'>
                    <block className='message info row_2'>
                        <row justify='center' align='center'>
                            <Icon use='#icon_info' />
                            <p>Выбери все удобства, которые есть в клубе</p>
                        </row>
                    </block>

                    <column className='others__list row_3'>
                        <Checkbox 
                            className='others__list-child' 
                            title='Кухня' 
                            onChange={e => handleToggleOther('kitchen', e.target.checked)} />
                        <Checkbox 
                            className='others__list-child' 
                            title='Кальян' 
                            onChange={e => handleToggleOther('hookah', e.target.checked)} />            
                        <Checkbox 
                            className='others__list-child' 
                            title='Гардероб' 
                            onChange={e => handleToggleOther('wardrobe', e.target.checked)} />            
                        <Checkbox 
                            className='others__list-child' 
                            title='Парковка' 
                            onChange={e => handleToggleOther('parking', e.target.checked)} />            
                        <Checkbox 
                            className='others__list-child' 
                            title='Алкоголь' 
                            onChange={e => handleToggleOther('alcohol', e.target.checked)} />            
                        <Checkbox 
                            className='others__list-child' 
                            title='Стримерская' 
                            onChange={e => handleToggleOther('streamroom', e.target.checked)} />            
                        <Checkbox 
                            className='others__list-child' 
                            title='Кнопка вызова админа' 
                            onChange={e => handleToggleOther('admincallbutton', e.target.checked)} />            
                        <Checkbox 
                            className='others__list-child' 
                            title='Девайсы на выбор' 
                            onChange={e => handleToggleOther('devicechoose', e.target.checked)} />            
                        <Checkbox 
                            className='others__list-child' 
                            title='Программы для монтажа' 
                            onChange={e => handleToggleOther('editingapps', e.target.checked)} />            
                    </column>
                </column>
            </div>

            <row className='row_4' justify='between'>
                <Button 
                    color='secondary'
                    label='Назад' 
                    icon={{ use: '#icon_back', direction: false }} 
                    onClick={_ => Service.setActiveSlide(4)} />

                <Button 
                    label='Далее' 
                    icon={{ use: '#icon_next', direction: true }} 
                    onClick={handleNextSlide} />
            </row>
        </column>
    )
}

export default observer(Slide5)
