import { forwardRef, useEffect, useState } from 'react'

import '../../../Styles/Components/Checkbox.scss'
import { GenerateToken } from '../../Controller/Utils'

const Checkbox = forwardRef(({ title, color, disabled, className, ...props }, ref) => {
    let classList = [ 'f-checkbox' ]

    let [ID, setID] = useState('')

    useEffect(_ => {
        setID('checkbox_' + GenerateToken(5))
    }, [])

    if (color) classList.push(`f-checkbox__${color}`)
    if (disabled) classList.push(`f-checkbox__disabled`)
    if (className) classList.push(className)

    return (
        <label className={classList.join(' ')} for={ID}>
            <input className='f-checkbox__input' type='checkbox' disabled={disabled || 0} {...props} id={ID} />
            {
                title &&
                <text className='f-checkbox__title'>{title}</text>
            }
        </label>
    )
})

export default Checkbox